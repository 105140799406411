<template>
  <div class="bottom">
    <div class="title">宇普西隆(杭州)信息技术有限公司</div>
    <div class="sub_title">
      yupuxilong@2023 未来+智感物业版权所有
      <a class="link" href="https://beian.miit.gov.cn">浙ICP备18025992号-1</a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>
<style scoped lang="less">
.bottom {
  width: 1920px;
  height: 132px;
  text-align: center;
  background: #333;
  .title {
    padding-top: 40px;
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
  }
  .sub_title {
    margin-top: 18px;
    font-size: 10px;
    font-weight: 400;
    color: #ffffff;
  }
  .link{
    color: #ffffff;
    text-decoration: none;
  }
}
</style>
