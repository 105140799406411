<template>
    <div class="banner">
   
      <el-carousel height="554px" arrow="never" indicator-position="none">
      <el-carousel-item v-for="item in images" :key="item">
        <img class="img" :src="item" />
      </el-carousel-item>
    </el-carousel>
    </div>
  </template>
  <script>

  export default {
   
    data() {
      return {
       
        images: [
          require("../assets/home/home_banner_wy.png"),

        ],
      };
    },
    created() {},
    mounted() {},
    computed: {
      
    },
    methods: {
     
     
    },
    watch: {},
  };
  </script>
  <style scoped lang="less">
  /deep/.el-carousel__indicator--horizontal .el-carousel__button {
    width: 10px;
    height: 10px;
    background: #fff;
   
    border-radius: 50%;
    opacity: 0.5;
  }  
  /deep/.el-carousel__indicator--horizontal.is-active .el-carousel__button{
    width: 10px;
    height: 10px;
    background: #45A164;
    border-radius: 50%;
    opacity: 1;
  }

  .img{
    width: 100%;
    height: 100%;

  }
  </style>
  