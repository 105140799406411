<template>
  <div class="page_warp">
    <div class="magetop" id="top">
      <div class="page_top" ref="top">
        <div class="top_menu_img">
          <img src="../assets/food_menu.png" />
        </div>

        <div class="top_menu">
          <div class="tab_view">
            <div
              ref="iWidth"
              class="menu_item"
              :class="menu_index == index ? 'menu_item_active' : ''"
              v-for="(item, index) in menuList"
              :key="index"
              @click="changeMenu(index, $event)"
            >
              <div v-if="index !=1">{{ item }}</div>

              <div class="drown_menu" v-else>
                <el-dropdown
                  :class="menu_index == 1 ? 'drop_down_active':'drop_down'"
                  placement="bottom"
                  @command="handleCommand"
                >
                  <span class="dropdown-link">解决方案</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command=0 :class="drop_index ==0 ? 'drop_item_active':''">仓储超市</el-dropdown-item>
                    <el-dropdown-item command=1 :class="drop_index ==1 ? 'drop_item_active':''">资源通</el-dropdown-item>
                    <el-dropdown-item command=2 :class="drop_index ==2 ? 'drop_item_active':''">增值服务</el-dropdown-item>
                    <el-dropdown-item command=3 :class="drop_index ==3 ? 'drop_item_active':''">时尚轻食</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>

            <div
              class="tab-activation-item"
              :style="{
                left: left + 'px',
                width: width + 'px',
              }"
            >
              <div class="tab_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box" id="box">
      <Banner></Banner>

      <div class="mine">
        <router-view></router-view>
      </div>
      <CustomBottom></CustomBottom>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/customBanner";
import CustomBottom from "@/components/customBottom";
export default {
  components: {
    Banner,
    CustomBottom
  },
  data() {
    return {
      menuList: ["首页", "解决方案", "关于我们", "联系我们"],
      menu_index: 0,
      left: 90,
      width: 0,
      iWidths: 0,
      norWidtList: [36, 72, 72, 72],
      activeWidthList: [60, 120, 120, 120],
      drop_index:999
    };
  },
  created() {
    console.log("router---", this.$router);
  },
  mounted() {
    this.tabLineChange();
    this.onresize();
  },
  computed: {},
  methods: {
    onresize() {
      var browerWidth = window.innerWidth; //浏览器可视宽度
      var baseWidth = 1920; //设计稿宽度
      var zoomValue = browerWidth / baseWidth; //缩放比例计算
      document.getElementById("box").style.transform =
        "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id

      document.getElementById("top").style.transform =
        "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id

      window.onresize = function() {
        //窗口尺寸变化时，重新计算和缩放
        browerWidth = window.innerWidth;
        zoomValue = browerWidth / baseWidth;
        document.getElementById("box").style.transform =
          "scale(" + zoomValue + "," + zoomValue + ")";

        document.getElementById("top").style.transform =
          "scale(" + zoomValue + "," + zoomValue + ")";
      };
    },

    tabLineChange() {
      let data = document.getElementsByClassName("menu_item");

      let activeData = document.getElementsByClassName("menu_item_active");
      let space = 90;

      this.width = this.activeWidthList[this.menu_index];
      space = this.menu_index * 90 + 90;
      this.left =
        this.norWidtList.slice(0, this.menu_index).reduce((num, item) => {
          return num + item;
        }, 0) + space;
    },

    changeMenu(index, e) {
      //   if (index == 6) {
      //     this.bol = true;
      //     return;
      //   }
      if (index != this.menu_index) {
        if (index == 1) return;
        this.menu_index = index;
        if (index == 0) {
          this.$router.push({
            path: "/Home"
          });
        } else if (index == 2) {
          this.$router.push({
            path: "/us"
          });
        } else if (index == 3) {
          this.$router.push({
            path: "/contact"
          });
        }
      }
    },
    handleCommand(command) {
      this.menu_index = 1;
      console.log('command-------',command)
      // this.drop_index = command
      if (command == 0) {
        this.$router.push({
          path: "/supermarket"
        });
      } else if (command == 1) {
        this.$router.push({
          path: "/Resource"
        });
      } else if (command == 2) {
        this.$router.push({
          path: "/Value"
        });
      } else if (command == 3) {
        this.$router.push({
          path: "/qsModel"
        });
      }
    }
  },
  watch: {
    menu_index(newVal) {
      this.menu_index = newVal;
      this.$nextTick(() => {
        this.tabLineChange();
      });
    },
    $route: {
      handler: function(val, oldVal) {
        this.drop_index = 999;
        console.log("新路由---", val);
        if (val.path == "/Home" && this.menu_index != 0) {
          this.menu_index = 0;
         
        }
        if (
          (val.path == "/supermarket" ||
            val.path == "/Resource" ||
            val.path == "/Value" ||
            val.path == "/qsModel") 
         
        ) {
          this.menu_index = 1;
           if(val.path == "/supermarket"){
            this.drop_index = 0;
           }else if(val.path == "/Resource"){
            this.drop_index = 1;
           }else if(val.path == "/Value"){
            this.drop_index = 2;
           }else if(val.path == "/qsModel"){
            this.drop_index = 3;
           }

        }

        if (val.path == "/us" && this.menu_index != 2) {
          this.menu_index = 2;
        }

        if (val.path == "/contact" && this.menu_index != 3) {
          this.menu_index = 3;
        }
      },
      // 深度观察监听
      deep: true
    }
  }
};
</script>
<style scoped lang="less">
.page_warp {
  height: 100vh;
}
.box {
  margin-top: 5%;
  display: inline-block;
  width: 1920px;
  // height: 2500px;
  transform-origin: 0 0;
  position: absolute;
  left: 0;
}

.drop_down_active {
  width: 100%;
  height: 100%;

  .dropdown-link {
    display: inline-block;
    color: #3ca35f;
    font-size: 30px;
  }

}
.drop_item_active{
  color: #3ca35f;
}
.drop_down {
  width: 100%;
  height: 100%;
  .dropdown-link {
    display: inline-block;
    font-size: 18px;
    color: #333;
  }
}

.magetop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 1920px;
  transform-origin: 0 0;
  .page_top {
    width: 100%;
    height: 80px;
    z-index: 100;
    background: #fff;
    overflow: hidden;
    // opacity: 1;
    .top_menu_img {
      position: sticky;
      top: 15px;
      left: 3%;
      z-index: 100;
      float: left;
      img {
        width: 112px;
        height: 49px;
      }
    }
    .top_menu {
      position: sticky;
      left: 10%;
      top: 10px;
      float: left;
      z-index: 100;

      .menu_item {
        margin-left: 90px;
        font-size: 18px;
        font-weight: 400;
        color: #333;
        transition: all 0.3s;
        cursor: pointer;
      }

      .menu_item_active {
        margin-left: 90px;
        font-size: 30px;
        font-weight: bold;
        color: #3ca35f;
      }
    }
  }
}

.tab-activation-item {
  position: absolute;
  height: 30px;
  // width: 36px;
  top: 54px;
  left: 0;
  z-index: 9;
  transition: all 0.3s linear;
  border-radius: 3px;
}
.tab_line {
  width: 36px;
  height: 5px;
  background: #3ca35f;
  border-radius: 3px;
  margin: 10px auto 0;
}
.tab_view {
  min-width: 100%;
  height: 80px;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.mine {
  width: 100%;
  height: calc(100% - 916px);
}
</style>
