import Vue from 'vue'
import ElementUI from 'element-ui';
import router from "./router/index";
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import animated from 'animate.css';
Vue.use(animated);
Vue.use(ElementUI);
Vue.config.productionTip = false
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  // 需要配置的属性 注意属性并没有引号
  title: false,
  toolbar: false
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
