import Vue from "vue";
import VueRouter from "vue-router";
import Lout from "@/components/customTopMenu.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/Home",
    component: Lout,
    children: [
      {
        path: "/Home",
        name: "Home",
        meta: {
          bol: false,
          name: "首页"
        },
        component: () => import("@/views/HelloWorld.vue"),
      },

      {
        path: "/media",
        name: "media",
        meta: {
          bol: false,
          name: "物业"
        },
        component: () => import("@/components/media.vue"),
      },
      {
        path: "/Value",
        name: "Value",
        meta: {
          bol: false,
          name: "Value"
        },
        component: () => import("@/components/Value.vue"),
      },
      {
        path: "/us",
        name: "us",
        meta: {
          bol: false,
          name: "us"
        },
        component: () => import("@/components/us.vue"),
      },
      {
        path: "/contact",
        name: "contact",
        meta: {
          bol: false,
          name: "contact"
        },
        component: () => import("@/components/contact.vue"),
      },
      {
        path: "/Repair",
        name: "Repair",
        meta: {
          bol: false,
          name: "Repair"
        },
        component: () => import("@/components/Repair.vue"),
      },
      {
        path: "/supermarket",
        name: "supermarket",
        meta: {
          bol: false,
          name: "supermarket"
        },
        component: () => import("@/components/supermarket.vue"),
      },
      {
        path: "/Payment",
        name: "Payment",
        meta: {
          bol: false,
          name: "Payment"
        },
        component: () => import("@/components/Payment.vue"),
      },
      {
        path: "/qsModel",
        name: "qsModel",
        meta: {
          bol: false,
          name: "轻食"
        },
        component: () => import("@/components/qsModel.vue"),
      },
      {
        path: "/wyModuleListModel",
        name: "wyModuleListModel",
        meta: {
          bol: false,
          name: "物业模块"
        },
        component: () => import("@/components/wyModuleListModel.vue"),
      },
      {
        path: "/Resource",
        name: "Resource",
        meta: {
          bol: false,
          name: "资源通"
        },
        component: () => import("@/components/Resource.vue"),
      },
    ]
  },
];

const router = new VueRouter({
  routes,
});

const originalPush = VueRouter.prototype.push;

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

//路由导航守卫
//进入到任何一个路由都会执行
router.beforeEach((to, from, next) => {


  if (to.matched.length !== 0) {
    if (to.path == "/") return next()

    next()
  } else {
    next();
  }



});
router.afterEach(() => {

});

export default router;
